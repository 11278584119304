import React, { ReactNode, createContext, useEffect, useState } from "react";
import { LANGS, THEMES } from "../config";

export interface SettingsType {
  theme: string;
  lang: string;
}
interface SettingsContextType {
  settings: SettingsType;
  saveNewSettings: (newSettings: SettingsType) => void;
}
interface SettingsProviderProps {
  children: ReactNode;
}
const SettingsContext = createContext<SettingsContextType>({
  settings: {
    theme: THEMES.DARK,
    lang: LANGS.EN,
  },
  saveNewSettings: () => {},
});

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [settings, setSettings] = useState<any>(null);

  const saveNewSettings = (newSettings: any) => {
    setSettings(newSettings);
    localStorage.setItem("settings", JSON.stringify(newSettings));
  };

  useEffect(() => {
    const rawSettings = localStorage.getItem("settings");
    const initSettings = rawSettings
      ? (JSON.parse(rawSettings) as SettingsType)
      : null;

    if (initSettings) {
      setSettings(initSettings);
    } else {
      setSettings({
        lang: LANGS.EN,
        theme: THEMES.DARK,
      });
      localStorage.setItem(
        "settings",
        JSON.stringify({
          lang: LANGS.EN,
          theme: THEMES.DARK,
        })
      );
    }
  }, []);

  if (!settings) {
    return null;
  }

  return (
    <SettingsContext.Provider value={{ settings, saveNewSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };
