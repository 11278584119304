import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { sections_questions } from "mocks";
import Loader from "components/Loader";
const Section = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const section_id = "1";

  const start_questionnaire = () => {
    navigate(`/onboarding/questionnaire/section/questions`);
  };

  const current_section = sections_questions.find(
    (section) => section.id === parseInt(section_id)
  );

  if (!current_section) {
    return <Loader />;
  }

  return (
    <Box pb={1}>
      <Container maxWidth="md">
        <MainCard>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2" color={"grey"} textAlign={"center"}>
                {`${t("questionnaire.section")} ${
                  sections_questions.findIndex(
                    (section) =>
                      section.id === parseInt(section_id ? section_id : "1")
                  ) + 1
                } ${t("questionnaire.of")} ${sections_questions.length}`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" textAlign={"center"}>
                {current_section.section_name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" textAlign={"center"} px={3}>
                This section asks you very general questions about your goals,
                views on work and models of professional practice.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} mt={2}>
                <Box display={"flex"} justifyContent={"end"}>
                  <Button variant="contained">
                    <Typography
                      variant="body1"
                      textAlign={"center"}
                      onClick={start_questionnaire}
                    >
                      {t("questionnaire.start")}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Container>
    </Box>
  );
};

export default Section;
