export const sections_questions = [
  {
    section_name: "Team Leader Questionnaire",
    id: 1,
    questions: [
      {
        id: 1,
        question: "What do I have to do?",
        time: 10, //in seconds
        max_options: 2,
        options: [
          {
            id: 1,
            option_value_gr: "option_1",
            option_value_en: "option_13232",
          },
          {
            id: 2,
            option_value_gr: "option_2",
            option_value_en: "option_233",
          },
          {
            id: 3,
            option_value_gr: "option_3",
            option_value_en: "option_3",
          },
          {
            id: 4,
            option_value_gr: "option_4",
            option_value_en: "option_4",
          },
        ],
      },
      {
        id: 1,
        question: "What do I have to do?1",
        max_options: 1,
        time: 10, //in seconds
        options: [
          {
            id: 1,
            option_value_gr: "option_1",
            option_value_en: "option_1",
          },
          {
            id: 2,
            option_value_gr: "option_2",
            option_value_en: "option_2",
          },
          {
            id: 3,
            option_value_gr: "option_3",
            option_value_en: "option_3",
          },
          {
            id: 4,
            option_value_gr: "option_4",
            option_value_en: "option_4",
          },
        ],
      },
      {
        id: 1,
        question: "What do I have to do?2",
        time: 10, //in seconds
        max_options: 2,
        options: [
          {
            id: 1,
            option_value_gr: "option_1",
            option_value_en: "option_1",
          },
          {
            id: 2,
            option_value_gr: "option_2",
            option_value_en: "option_2",
          },
          {
            id: 3,
            option_value_gr: "option_3",
            option_value_en: "option_3",
          },
          {
            id: 4,
            option_value_gr: "option_4",
            option_value_en: "option_4",
          },
        ],
      },
    ],
  },
  {
    section_name: "General Back ground",
    id: 2,
    questions: [
      {
        id: 1,
        question: "What do I have to do?",
        time: 10, //in seconds
        max_options: 1,
        options: [
          {
            id: 1,
            option_value_gr: "option_1",
            option_value_en: "option_1",
          },
          {
            id: 2,
            option_value_gr: "option_2",
            option_value_en: "option_2",
          },
          {
            id: 3,
            option_value_gr: "option_3",
            option_value_en: "option_3",
          },
          {
            id: 4,
            option_value_gr: "option_4",
            option_value_en: "option_4",
          },
        ],
      },
    ],
  },
  {
    section_name: "Other questions",
    id: 3,

    questions: [
      {
        id: 1,
        question: "What do I have to do?",
        time: 30, //in seconds
        max_options: 1,
        options: [
          {
            id: 1,
            option_value_gr: "option_1",
            option_value_en: "option_1",
          },
          {
            id: 2,
            option_value_gr: "option_2",
            option_value_en: "option_2",
          },
          {
            id: 3,
            option_value_gr: "option_3",
            option_value_en: "option_3",
          },
          {
            id: 4,
            option_value_gr: "option_4",
            option_value_en: "option_4",
          },
        ],
      },
    ],
  },
];
