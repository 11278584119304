// material-ui
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/images/icons/logo.png";

const Logo = () => {
  const theme = useTheme();

  return <img src={logo} alt="shopstream" width="250" />;
};

export default Logo;
