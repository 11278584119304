import { Container, Grid } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect } from "react";
import { get_demo_group, get_user_group } from "slices/groupSlice";
import { useDispatch, useSelector } from "store";
import UserGroup from "./UserGroup";
import Loader from "components/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { data: demo_group, status: demo_group_status } = useSelector(
    (state) => state.group.demo_group
  );
  const { data: user_group, status: user_group_status } = useSelector(
    (state) => state.group.user_group
  );

  useEffect(() => {
    dispatch(get_demo_group());
    dispatch(get_user_group());
  }, [dispatch]);

  if (demo_group_status !== "fetched" || user_group_status !== "fetched") {
    return <Loader />;
  }
  return (
    <MainCard title="Dashboard">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <UserGroup />
          </Grid>
          <Grid item xs={12} md={6}>
            <MainCard title="User Group">
              <Container>
                <pre>{JSON.stringify(user_group, null, 2)}</pre>
              </Container>
            </MainCard>
          </Grid>
        </Grid>
      </Container>
    </MainCard>
  );
};

export default Dashboard;
