// material-ui
import { Box, useMediaQuery } from "@mui/material";
import Profile from "./Profile";
import Notification from "./Notification";
import MobileSection from "./MobileSection";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { LANGS } from "../../../../config";
import { Select, MenuItem, Typography } from "@mui/material";
import { useSettings } from "../../../../hooks/useSettings";
import { Language } from "@mui/icons-material";
import LanguageSwitcher from "components/LanguageSwitcher";
import BrightnessToggleButton from "components/BrightnessToggleButton";

const HeaderContent = () => {
  const { settings, saveNewSettings } = useSettings();
  const { i18n } = useTranslation();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }} />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      <LanguageSwitcher />
      <BrightnessToggleButton />

      <Notification />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
