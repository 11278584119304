// material-ui
import { Box, useMediaQuery } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, Typography } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { LANGS } from "config";

const LanguageSwitcher = () => {
  const { settings, saveNewSettings } = useSettings();
  const { i18n } = useTranslation();

  return (
    <Select
      sx={{
        minWidth: 100,
        minHeight: 40,
        ml: { xs: 0, md: 1 },
        "& .MuiSelect-select": {
          py: 0.5,
          pl: 1,
          pr: 2,
        },
      }}
      value={settings.lang}
      onChange={(e) => {
        i18n.changeLanguage(e.target.value);
        saveNewSettings({ lang: e.target.value, theme: settings.theme });
      }}
    >
      <MenuItem value={LANGS.EN}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <ReactCountryFlag
            countryCode="GB"
            svg
            style={{
              fontSize: "1.5em",
              lineHeight: "2em",
              marginRight: "1em",
            }}
          />
          <Typography variant="body1">EN</Typography>
        </Box>
      </MenuItem>

      <MenuItem value={LANGS.EL}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <ReactCountryFlag
            countryCode="GR"
            svg
            style={{
              fontSize: "1.5em",
              lineHeight: "2em",
              marginRight: "1em",
            }}
          />
          <Typography variant="body1">EL</Typography>
        </Box>
      </MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
