import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CardContent,
  Link,
  TableContainer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

interface CalculatorResultsProps {
  values: any;
  handleChange: any;
  handleBlur: any;
}
const CalculatorResults: FC<CalculatorResultsProps> = ({
  values,
  handleChange,
  handleBlur,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  let calculations: any = {
    b5: values.number_of_staff,
    b6: values.team_size,
  };

  calculations.b9 = 2250;
  calculations.b10 = calculations.b9 * calculations.b5;
  calculations.b11 = _.round(calculations.b5 * 0.35 * 17450, 2);
  calculations.b12 = calculations.b6 * 0.1 * calculations.b9;
  calculations.b13 = calculations.b5 * 650;
  calculations.b15 =
    calculations.b10 + calculations.b11 + calculations.b12 + calculations.b13;

  calculations.b16 = _.round(calculations.b15 * 0.7, 2);
  calculations.b17 = calculations.b5 * 240 + calculations.b5 * 12;
  calculations.b19 = _.round(
    calculations.b15 - calculations.b16 - calculations.b17,
    2
  );

  const total_cost = _.round(calculations.b15, 0);
  const cost_with_tp360 = _.round(calculations.b16, 0);
  const subscription_cost = _.round(calculations.b17, 0);
  const savings_with_tp360 = _.round(calculations.b19, 0);

  // console.log("calculations", calculations);
  // console.log("total_cost", total_cost);
  // console.log("cost_with_tp360", cost_with_tp360);
  // console.log("subscription_cost", subscription_cost);
  // console.log("savings_with_tp360", savings_with_tp360);

  const format_thousands = (num: any) => {
    const numStr = num.toString();

    return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Grid container sx={{ spacing: { xs: 0, md: 2 } }}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            {isXs ? (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t("calculator.projected_cost")}</TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" fontWeight={"700"}>
                        £{format_thousands(total_cost)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t("calculator.projected_cost_with_tp360")}
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        fontWeight={"700"}
                        color="primary"
                      >
                        £{format_thousands(cost_with_tp360)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("calculator.subscription_cost")}</TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" fontWeight={"700"}>
                        £{format_thousands(subscription_cost)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("calculator.potential_savings")}</TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        fontWeight={"700"}
                        color="primary"
                      >
                        £{format_thousands(savings_with_tp360)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">
                      {t("calculator.without_team_player")}
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        color="primary"
                        variant="h6"
                        fontWeight={"600"}
                      >
                        {t("calculator.with_team_player")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t("calculator.projected_hr_cost")}</TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" fontWeight={"700"}>
                        £ {format_thousands(total_cost)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" fontWeight={"700"}>
                        £ {format_thousands(cost_with_tp360)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("calculator.subscription_cost")}</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" fontWeight={"700"}>
                        £ {format_thousands(subscription_cost)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("calculator.potential_savings")}</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        fontWeight={"700"}
                        color="primary"
                      >
                        £ {format_thousands(savings_with_tp360)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            <Box mt={2}>
              <Typography variant="caption" color={"GrayText"}>
                {`${t("calculator.based_on_uk_average_salaries")} £34,900`}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="caption" color={"GrayText"}>
                {`${t("calculator.sources")} `}
                <Link
                  href="https://community.cipd.co.uk/cipd-blogs/b/cipd_voice_on/posts/why-staff-turnover-data-matters"
                  target="_blank"
                >
                  CIPD
                </Link>
                {` - `}
                <Link
                  href="https://www.centrichr.co.uk/hr-consultancy/attraction-and-retention/"
                  target="_blank"
                >
                  CentricHR
                </Link>
                {` - `}
                <Link
                  href="https://www.forbes.com/uk/advisor/business/average-uk-salary-by-age/#:~:text=The%20latest%20government%20data%20(published,salary%20of%20around%20%C2%A334%2C900"
                  target="_blank"
                >
                  Forbes
                </Link>
              </Typography>
            </Box>
            {/* <Box my={2}>
              <Grid container>
                <Grid item xs={12} md={9}>
                  <Typography variant="h5" textAlign={"center"}>
                    {t("calculator.projected_hr_cost")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    _ngcontent-wvd-c89=""
                    px={2}
                    sx={{
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <svg
                      _ngcontent-wvd-c89=""
                      viewBox="0 0 555 200"
                      className="graph"
                    >
                      <g _ngcontent-wvd-c89="">
                        <path
                          _ngcontent-wvd-c89=""
                          id="svg_1"
                          d="m10,190c1,0 313,24 532,-139"
                          stroke-width="3.5"
                          stroke="#008cb2"
                          fill="none"
                        ></path>
                        <path
                          _ngcontent-wvd-c89=""
                          id="svg_2"
                          d="m10,190c1,0 313,24 532,-190"
                          stroke-width="3.5"
                          stroke="#d93f36"
                          fill="none"
                        ></path>
                      </g>
                    </svg>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        right: "-20px",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Typography fontWeight={"600"}>
                         {`${format_thousands(
                          _.round(cost_without_tphr / 1000, 0)
                        )} k`} 
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "25px",
                        right: "-20px",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Typography fontWeight={"600"}>
                        {/* {`${format_thousands(
                          _.round(cost_with_tphr / 1000, 0)
                        )} k`} 
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CalculatorResults;
