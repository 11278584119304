import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useDispatch } from "store";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const initialValues = {
  invite_participants: false,
  invite_team_leader: false,
  create_team: false,
  generate_report: false,
  loading: false,
};

const OnboardingWelcome = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box pb={1}>
      <Container maxWidth="md">
        <MainCard title="Onboarding">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" textAlign={"center"}>
                Welcome to the onboarding process
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign={"center"} px={3}>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste
                accusantium saepe quos aperiam ad hic. Voluptatum, laborum
                quaerat? Doloremque at modi ullam dolorum tempora dicta
                quibusdam magnam dolor necessitatibus veritatis.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setValues }) => {
                  //   submit_form(values, setValues);
                  navigate("/onboarding/team");
                }}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  touched,
                  handleChange,
                  setValues,
                }) => {
                  return (
                    <Form>
                      {/* invite_participants: false,
                            invite_team_leader: false,
                            create_team: false,
                            generate_report: false, */}
                      <Grid item xs={12} pb={2}>
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <Switch
                              name="invite_participants"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.invite_participants}
                              sx={{ marginRight: 2 }}
                            />
                          }
                          label={
                            <Typography variant="body1" textAlign={"center"}>
                              {t("onboarding.invite_participants")}{" "}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} pb={2}>
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <Switch
                              name="invite_team_leader"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.invite_team_leader}
                              sx={{ marginRight: 2 }}
                            />
                          }
                          label={
                            <Typography variant="body1" textAlign={"center"}>
                              {t("onboarding.invite_team_leader")}{" "}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} pb={2}>
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <Switch
                              name="create_team"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.create_team}
                              sx={{ marginRight: 2 }}
                            />
                          }
                          label={
                            <Typography variant="body1" textAlign={"center"}>
                              {t("onboarding.create_team")}{" "}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} pb={2}>
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <Switch
                              name="generate_report"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.generate_report}
                              sx={{ marginRight: 2 }}
                            />
                          }
                          label={
                            <Typography variant="body1" textAlign={"center"}>
                              {t("onboarding.generate_report")}{" "}
                            </Typography>
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          disabled={values.loading}
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                          >
                            <Typography variant="h5">
                              {t("onboarding.get_started")}
                            </Typography>

                            {values.loading && (
                              <CircularProgress color="primary" size={25} />
                            )}
                          </Box>
                        </Button>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </MainCard>
      </Container>
    </Box>
  );
};

export default OnboardingWelcome;
