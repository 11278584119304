import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useDispatch } from "store";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { set } from "lodash";

const initialValues = {
  team_name: "",
  invites: [] as string[],
  loading: false,
};

const OnBoardingTeam = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const create_team = (
    values: any,
    setValues: any,
    setErrors: any,
    setTouched: any
  ) => {
    const invites = [...values.invites, values.team_name];
    setValues({ ...values, invites, team_name: "" });
    setErrors((errors: any) => {
      return { ...errors, team_name: "" };
    });
    setTouched((touched: any) => {
      return { ...touched, team_name: false };
    });

    navigate("/onboarding/invite");
  };

  const validationSchema = Yup.object({
    team_name: Yup.string().max(255).required(t("onboarding.required")),
  });

  return (
    <Box pb={1}>
      <Container maxWidth="md">
        <MainCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" textAlign={"center"}>
                {t("onboarding.step_1")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" textAlign={"center"} fontWeight={900}>
                {t("onboarding.give_your_team_a_name")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" textAlign={"center"}>
                {`${t("onboarding.description_of_how_they_can_use_teams")}`}
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setValues, setErrors, setTouched }) => {
                  create_team(values, setValues, setErrors, setTouched);
                }}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  touched,
                  handleChange,
                  setValues,
                  setErrors,
                  setTouched,
                }) => {
                  return (
                    <Form>
                      <Grid item container xs={12}>
                        <Grid item xs={12} md={9}>
                          <TextField
                            fullWidth
                            id="team_name"
                            name="team_name"
                            label={t("onboarding.team_name")}
                            value={values.team_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.team_name && errors.team_name
                            )}
                            helperText={touched.team_name && errors.team_name}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            style={{ height: "57px" }}
                          >
                            <Typography variant="h5">
                              {t("onboarding.create_team")}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </MainCard>
      </Container>
    </Box>
  );
};

export default OnBoardingTeam;
