import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "../slices/userSlice";
import { reducer as menuReducer } from "../slices/menuSlice";
import { reducer as generalRecuser } from "../slices/generalSlice";
import { reducer as groupReducer } from "../slices/groupSlice";
import { reducer as questionaireReducer } from "../slices/questionaireSlice";

export const rootReducer = combineReducers({
  user: userReducer,
  menu: menuReducer,
  general: generalRecuser,
  group: groupReducer,
  questionaire: questionaireReducer,
});
