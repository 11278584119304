import { Box, useMediaQuery } from "@mui/material";
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import LanguageSwitcher from "components/LanguageSwitcher";
import BrightnessToggleButton from "components/BrightnessToggleButton";

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }} />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      <LanguageSwitcher />
      <BrightnessToggleButton />

      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
