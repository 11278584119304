import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "utils/axios";
import { FetchStatus } from "types/general";
import { Group } from "types/group";

interface GeneralState {
  questionaire: {
    current_questionaire: string;
    data: Group[];
    status: FetchStatus;
  };
}

const initialState: GeneralState = {
  questionaire: {
    current_questionaire: "",
    data: [],
    status: "not_fetched",
  },
};

const questionaireSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
});

export const reducer = questionaireSlice.reducer;

export const get_demo_group = () => async (dispatch: any) => {
  //   try {
  //     dispatch(questionaireSlice.actions.set_demo_group_fetch_status("fetching"));
  //     const response = await axiosInstance.get("/demo/group_joined");
  //     if (response.status === 200) {
  //       dispatch(questionaireSlice.actions.set_demo_group(response.data.data));
  //     } else {
  //       throw new Error("Failed to fetch countries");
  //     }
  //   } catch (error) {
  //     dispatch(questionaireSlice.actions.set_demo_group_fetch_status("failed"));
  //   }
};
