import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "utils/axios";
import { FetchStatus } from "types/general";
import { Group } from "types/group";

interface GeneralState {
  demo_group: {
    data: Group[];
    status: FetchStatus;
  };
  user_group: {
    data: Group[];
    status: FetchStatus;
  };
}

const initialState: GeneralState = {
  demo_group: {
    data: [],
    status: "not_fetched",
  },
  user_group: {
    data: [],
    status: "not_fetched",
  },
};

const groupSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set_demo_group_fetch_status(
      state: GeneralState,
      action: PayloadAction<FetchStatus>
    ) {
      state.demo_group.status = action.payload;
    },
    set_demo_group(state: GeneralState, action: PayloadAction<Group[]>) {
      state.demo_group.data = action.payload;
      state.demo_group.status = "fetched";
    },
    set_user_group_fetch_status(
      state: GeneralState,
      action: PayloadAction<FetchStatus>
    ) {
      state.user_group.status = action.payload;
    },
    set_user_group(state: GeneralState, action: PayloadAction<Group[]>) {
      state.user_group.data = action.payload;
      state.user_group.status = "fetched";
    },
  },
});

export const reducer = groupSlice.reducer;

export const get_demo_group = () => async (dispatch: any) => {
  try {
    dispatch(groupSlice.actions.set_demo_group_fetch_status("fetching"));
    const response = await axiosInstance.get("/demo/group_joined");
    if (response.status === 200) {
      dispatch(groupSlice.actions.set_demo_group(response.data.data));
    } else {
      throw new Error("Failed to fetch countries");
    }
  } catch (error) {
    dispatch(groupSlice.actions.set_demo_group_fetch_status("failed"));
  }
};

export const get_user_group = () => async (dispatch: any) => {
  try {
    dispatch(groupSlice.actions.set_user_group_fetch_status("fetching"));
    const response = await axiosInstance.get("/user/group_joined");
    if (response.status === 200) {
      dispatch(groupSlice.actions.set_user_group(response.data.data));
    } else {
      throw new Error("Failed to fetch countries");
    }
  } catch (error) {
    dispatch(groupSlice.actions.set_user_group_fetch_status("failed"));
  }
};
