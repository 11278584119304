import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { sections_questions } from "mocks";
const Questionnaire = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const current_section = 1;

  const find_current_section = () => {
    return current_section;
  };
  const start_questionnaire = () => {
    console.log("Start questionnaire");
    console.log(sections_questions);

    navigate(`/onboarding/questionnaire/${find_current_section()}`);
  };

  return (
    <Box pb={1}>
      <Container maxWidth="md">
        <MainCard title="Onboarding">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" textAlign={"center"}>
                Team Leader Questionnaire
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign={"center"} px={3}>
                What do I have to do? The TeamPlayerHR is simple to complete.
                You will be asked a series of questions, and you will be asked
                to select an answer. You should answer as fast as you can
                because questions are time based and will fade out after a
                period of time. However, don’t worry: we have found that most
                people have no trouble answering the questions; and, if you
                haven’t answered all questions, the computer will come back and
                prompt you again. There will be four sections. At the beginning
                of each section, you will be given specific instructions. Each
                time you complete a section, your answers will be automatically
                saved. If you fail to answer a question, the computer will come
                back and prompt you. It is important that when you are filling
                out the TeamPlayerHR, you should go with the first answer that
                seems right to you. Do not think too long or hard about any
                answer. And, remember—there are no right or wrong answers. Just
                be as honest as you can be and trust your first response.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} mt={2}>
                <Box display={"flex"} justifyContent={"end"}>
                  <Button variant="contained" onClick={start_questionnaire}>
                    <Typography variant="body1" textAlign={"center"}>
                      {t("onboarding.begin_questionnaire")}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Container>
    </Box>
  );
};

export default Questionnaire;
