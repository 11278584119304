import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  InputAdornment,
  Stack,
  FormControl,
  IconButton,
  Autocomplete,
  Switch,
  FormControlLabel,
  Link,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { strengthColor, strengthIndicator } from "utils/password-strength";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import Loader from "components/Loader";
import { Country, Occupation, Sector } from "types/general";
import Dropzone, { useDropzone } from "react-dropzone";
import axiosInstance from "utils/axios";
import CloseIcon from "@mui/icons-material/Close";

const debug = process.env.REACT_APP_DEBUG;

const initialValues = !debug
  ? {
      first_name: "",
      last_name: "",
      title: "",
      phone: "",
      country: "",
      address_line_1: "",
      address_line_2: "",
      state: "",
      city: "",
      zip: "",
      cv: "",
      sector: "",
      occupation: "",
      email: "",
      password: "",
      confirm_password: "",
      cv_name: "",
      agreeTerms: false,
      agreePrivacy: false,
      loading: false,
    }
  : {
      first_name: "giorgos",
      last_name: "papakonstantinou",
      title: "test",
      phone: "+306978789109",
      country: "84",
      address_line_1: "finikos 14",
      address_line_2: "14",
      state: "wewew",
      city: "polixnifd",
      zip: "56533",
      cv: "1y3Zi0SXYEYxCV6aEIzyk43mL7RbKAUiq8I26EnV.pdf",
      sector: "3",
      occupation: "112",
      email: "giorgos.papakonstantinou1999@gmail.com",
      password: "Grabi$123",
      confirm_password: "Grabi$123",
      cv_name: "20231028015057_e1596912.pdf",
      agreeTerms: true,
      agreePrivacy: true,
      loading: false,
    };
const IndividualRegister = () => {
  const { data: countries, status: countries_status } = useSelector(
    (state) => state.general.countries
  );
  const { data: sectors, status: sectors_status } = useSelector(
    (state) => state.general.sectors
  );
  const { data: occupations, status: occupations_status } = useSelector(
    (state) => state.general.occupations
  );
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [show_password, set_show_password] = useState(false);
  const [level, setLevel] = useState<any>();
  const dispatch = useDispatch();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const changePassword = (value: any) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const upload_file = async (file: any, setValues: any) => {
    const data = new FormData();
    data.append("file", file);

    setValues((prev: any) => ({
      ...prev,
      cv: "fetching",
    }));
    const response = await axiosInstance.post("/user/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      setValues((prev: any) => ({
        ...prev,
        cv: response.data.data.filename,
        cv_name: file.name,
      }));
      enqueueSnackbar(t("auth.cv_upload_success"), {
        variant: "success",
      });
    } else {
      setValues((prev: any) => ({
        ...prev,
        cv: "",
        cv_name: "",
      }));

      enqueueSnackbar(t("auth.cv_upload_error"), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    changePassword("");
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
    password: Yup.string()
      .max(255)
      .required(t("pages.register.passwordRequired"))
      .min(6, t("pages.register.passwordMinLength")),

    confirm_password: Yup.string()
      .max(255)
      .required(t("pages.register.passwordRequired"))
      .min(6, t("pages.register.passwordMinLength"))
      .oneOf([Yup.ref("password")], "Passwords must match"),

    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    phone: Yup.string().required(),
    country: Yup.string().required(),
    address_line_1: Yup.string().required(),
    state: Yup.string().required(),
    cv: Yup.string().required(),
    sector: Yup.string().required(),
    occupation: Yup.string().required(),
    zip: Yup.string().required(),
    agreeTerms: Yup.boolean().oneOf([true]),
    agreePrivacy: Yup.boolean().oneOf([true]),
  });

  const submit_form = async (
    temp_values: any,
    setValues: (values: any) => void
  ) => {
    const values = { ...temp_values };

    setValues((prev: any) => ({
      ...prev,
      loading: true,
    }));

    const body = {
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      agreePrivacy: values.agreePrivacy,
      agreeTerms: values.agreeTerms,
      city: values.city,
      confirm_password: values.confirm_password,
      country: values.country,
      cv: values.cv,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      occupation: values.occupation,
      password: values.password,
      phone: values.phone,
      sector: values.sector,
      state: values.state,
      title: values.title,
      zip: values.zip,
    };

    const response = await axiosInstance.post("/user/register", body);

    if (response.status === 200) {
      enqueueSnackbar(t("auth.register_success_check_your_mail"), {
        variant: "success",
      });
      navigate("/login");
    } else {
      enqueueSnackbar(response.data.message, {
        variant: "error",
      });
    }

    setValues((prev: any) => ({
      ...prev,
      loading: false,
    }));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setValues }) => {
        submit_form(values, setValues);
      }}
    >
      {({ values, errors, handleBlur, touched, handleChange, setValues }) => {
        return (
          <Form>
            {values.loading && <Loader />}
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              overflow="auto"
              // height={500}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Field
                      name="first_name"
                      value={values.first_name}
                      label={t("auth.first_name")}
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="last_name"
                    value={values.last_name}
                    label={t("auth.last_name")}
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    error={touched.last_name && Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="title"
                    value={values.title}
                    label={t("auth.title")}
                    as={TextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="phone"
                    type="tel"
                    value={values.phone}
                    label={t("auth.phone")}
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    onChange={(e, value: Country | null) => {
                      setValues({
                        ...values,
                        country: value ? value.id.toString() : "",
                      });
                    }}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label={t("auth.country")} />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="address_line_1"
                    value={values.address_line_1}
                    label={t("auth.address_line_1")}
                    as={TextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                    error={
                      touched.address_line_1 && Boolean(errors.address_line_1)
                    }
                    helperText={touched.address_line_1 && errors.address_line_1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="address_line_2"
                    value={values.address_line_2}
                    label={t("auth.address_line_2")}
                    as={TextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="state"
                    value={values.state}
                    label={t("auth.state")}
                    as={TextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                    error={touched.state && Boolean(errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="city"
                    value={values.city}
                    label={t("auth.city")}
                    as={TextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="zip"
                    value={values.zip}
                    label={t("auth.zip")}
                    as={TextField}
                    variant="outlined"
                    type="text"
                    fullWidth
                    error={touched.zip && Boolean(errors.zip)}
                    helperText={touched.zip && errors.zip}
                  />
                </Grid>
                <Grid item xs={12}>
                  {values.cv && values.cv === "fetching" && <Loader />}

                  <FormControl
                    fullWidth
                    error={touched.cv && Boolean(errors.cv)}
                  >
                    <Dropzone
                      disabled={values.cv ? true : false}
                      accept={{
                        "application/msword": [".doc"],
                        "application/pdf": [".pdf"],
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                          [".docx"],
                      }}
                      multiple={false}
                      onDrop={(added_files) => {
                        if (added_files.length === 0) {
                          enqueueSnackbar(t("auth.cv_upload_limit"), {
                            variant: "error",
                          });
                          return;
                        }

                        const file = added_files[0];
                        upload_file(file, setValues);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <Box
                            {...getRootProps()}
                            style={{
                              width: "100%",
                              height: "100px",
                              border: "1px solid #ccc",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "1rem",
                              borderRadius: "5px",
                              cursor: "pointer",
                              borderStyle: "dashed",
                            }}
                          >
                            <input {...getInputProps()} />
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {values.cv && values.cv !== "fetching" ? (
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  gap={2}
                                >
                                  <Typography variant="body1">
                                    {values.cv_name}
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        cv: "",
                                        cv_name: "",
                                      });
                                    }}
                                    color="error"
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              ) : (
                                <>
                                  <Typography variant="body1">
                                    {t("auth.place_your_cv")}
                                  </Typography>
                                  <Typography variant="body2">
                                    {t("auth.accepted_files")}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Box>
                        </section>
                      )}
                    </Dropzone>
                    <FormHelperText error>{errors.cv}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    onChange={(e, value: Sector | null) => {
                      setValues({
                        ...values,
                        sector: value ? value.id.toString() : "",
                      });
                    }}
                    options={sectors}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label={t("auth.sector")} />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    onChange={(e, value: Occupation | null) => {
                      setValues({
                        ...values,
                        occupation: value ? value.id.toString() : "",
                      });
                    }}
                    options={occupations}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label={t("auth.occupation")} />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="email"
                    value={values.email}
                    label={t("auth.email")}
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Field
                      as={TextField}
                      fullWidth
                      label={t("auth.password")}
                      error={Boolean(touched.password && errors.password)}
                      type={show_password ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      helperText={touched.password && errors.password}
                      onChange={(e: any) => {
                        handleChange(e);
                        changePassword(e.target.value);
                      }}
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => set_show_password(!show_password)}
                              onMouseDown={(e) => {
                                e.preventDefault();
                              }}
                              edge="end"
                            >
                              {show_password ? (
                                <VisibilityIcon fontSize="small" />
                              ) : (
                                <VisibilityOffIcon fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <Typography variant="subtitle1" fontSize="0.75rem">
                          {level?.label}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            bgcolor: level?.color,
                            width: 85,
                            height: 8,
                            borderRadius: "7px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="confirm_password"
                    value={values.confirm_password}
                    label={t("auth.confirm_password")}
                    as={TextField}
                    variant="outlined"
                    type="password"
                    error={
                      touched.confirm_password &&
                      Boolean(errors.confirm_password)
                    }
                    helperText={
                      touched.confirm_password && errors.confirm_password
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                      <Switch
                        name="agreeTerms"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.agreeTerms}
                        sx={{ marginRight: 2 }}
                      />
                    }
                    label={
                      <React.Fragment>
                        {t("auth.agree_to")}{" "}
                        <Link
                          href="https://teamplayerhr.com/terms-and-conditions"
                          target="_blank"
                        >
                          {t("auth.terms_and_condition")}{" "}
                        </Link>
                      </React.Fragment>
                    }
                  />
                  {errors.agreeTerms && touched.agreeTerms && (
                    <Box paddingLeft={2}>
                      <FormHelperText error>{errors.agreeTerms}</FormHelperText>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ margin: 0 }}
                    control={
                      <Switch
                        name="agreePrivacy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.agreePrivacy}
                        sx={{ marginRight: 2 }}
                      />
                    }
                    label={
                      <React.Fragment>
                        {t("auth.agree_to")}{" "}
                        <Link
                          href="https://teamplayerhr.com/privacy-policy"
                          target="_blank"
                        >
                          {t("auth.privacy_policy")}{" "}
                        </Link>
                      </React.Fragment>
                    }
                  />
                  {errors.agreePrivacy && touched.agreePrivacy && (
                    <Box paddingLeft={2}>
                      <FormHelperText error>
                        {errors.agreePrivacy}
                      </FormHelperText>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    disabled={values.loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Typography variant="h5">{t("auth.register")}</Typography>

                      {values.loading && (
                        <CircularProgress color="primary" size={25} />
                      )}
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
export default IndividualRegister;
