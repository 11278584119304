import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const OnboardingMobilePlanCard = () => {
  const { t } = useTranslation();

  return (
    <MainCard
      sx={{
        height: "100%",
      }}
      title={
        <Typography variant="h5" textAlign={"center"}>
          {t("onboarding.mobile_app_&_short_questionnaires")}
        </Typography>
      }
    >
      <Typography variant="body1" textAlign={"center"}>
        {t(
          "onboarding.candidates_are_able_to_complete_the_short_&_full_questionnaire_from_the_mobile_app"
        )}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <Box>
          <Box my={2}>
            <Typography variant="h5" textAlign={"center"}>
              {t("onboarding.benefits")}
            </Typography>

            <List>
              <ListItem>
                <Typography variant="body1" textAlign={"center"}>
                  {t("onboarding.short_questionnaire")}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1" textAlign={"center"}>
                  {t("onboarding.mobile_app")}
                </Typography>
              </ListItem>
            </List>
          </Box>

          <Typography variant="h5" textAlign={"center"}>
            £240/ {t("onboarding.employee")}
          </Typography>
          <Typography variant="body2" textAlign={"center"} color={"grey"}>
            +
          </Typography>
          <Typography variant="body2" textAlign={"center"} color={"grey"}>
            £1.00 /{t("onboarding.report")}/{t("onboarding.participant")}
          </Typography>
          <Typography variant="body2" textAlign={"center"} color={"grey"}>
            +
          </Typography>
          <Typography variant="body2" textAlign={"center"} color={"grey"}>
            £0.99 /{t("onboarding.participant")}
          </Typography>
          <Typography variant="body2" textAlign={"center"} color={"grey"}>
            {t("onboarding.minimum_participant_order_100")}
          </Typography>

          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <NavLink
              to="/onboarding/team_leader"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained">
                <Typography variant="body1" textAlign={"center"}>
                  {t("onboarding.purchase_plan")}
                </Typography>
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </MainCard>
  );
};

export default OnboardingMobilePlanCard;
