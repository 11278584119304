import { Box, Grid, Slider, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface CalculatorFieldsProps {
  values: any;
  setValues: any;
  handleChange: any;
  handleBlur: any;
}

const CalculatorFields: FC<CalculatorFieldsProps> = ({
  values,
  setValues,
  handleChange,
  handleBlur,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {`${t("calculator.number_of_staff")} :`}
          </Typography>
          <Typography variant="h6">{values.number_of_staff}</Typography>
        </Box>

        <Slider
          name="number_of_staff"
          value={values.number_of_staff}
          onChange={(e, value) => {
            if (value < values.team_size) {
              setValues({ number_of_staff: value, team_size: value });
            } else {
              setValues({ ...values, number_of_staff: value });
            }
          }}
          min={50}
          max={2500}
          valueLabelDisplay="auto"
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {`${t("calculator.team_size")} :`}
          </Typography>
          <Typography variant="h6">{values.team_size}</Typography>
        </Box>

        <Slider
          name="team_size"
          value={values.team_size}
          onChange={(e, value) => {
            if (value > values.number_of_staff) {
              setValues({ team_size: value, number_of_staff: value });
            } else {
              setValues({ ...values, team_size: value });
            }
          }}
          min={5}
          max={150}
          valueLabelDisplay="auto"
        />
      </Grid>
    </Grid>
  );
};

export default CalculatorFields;
