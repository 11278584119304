import DashboardIcon from "@mui/icons-material/Dashboard";

const icons = {
  DashboardIcon,
};

const dashboard = {
  id: "group-dashboard",
  title: "menu.navigation",
  type: "group",
  right: "all",
  children: [
    {
      id: "dashboard",
      title: "menu.dashboard",
      type: "item",
      url: "/home/dashboard",
      icon: icons.DashboardIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
