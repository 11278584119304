import { Box, Button, Container, Grid, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { sections_questions } from "mocks";
import Loader from "components/Loader";
import QuestionForm from "./QuestionForm";

const Questions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const section_id = "1";

  const start_questionnaire = () => {
    navigate(`/onboarding/questionnaire/section/questions`);
  };

  const current_section = sections_questions.find(
    (section) => section.id === parseInt(section_id)
  );
  let current_question_index = 0;
  const current_question = current_section?.questions[current_question_index];

  if (!current_section) {
    return <Loader />;
  }

  console.log("current_section", current_section);
  console.log("current_question", current_question);

  return (
    <Box pb={1}>
      <Container maxWidth="md">
        <MainCard>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2" color={"grey"} textAlign={"center"}>
                {`${current_section.section_name}`}
              </Typography>
              <Typography variant="body2" color={"grey"} textAlign={"center"}>
                {`${t("questionnaire.question")} ${current_question_index + 1}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <QuestionForm question={current_question} />
            </Grid>
          </Grid>
        </MainCard>
      </Container>
    </Box>
  );
};

export default Questions;
