import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useDispatch } from "store";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { set, values } from "lodash";
import Onboarding from "views/onboarding";
import OnboardingInviteTable from "./OnboardingInviteTable";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";

const initialValues = {
  email: "",
  invites: [] as string[],
  loading: false,
};

const OnboardingInviteForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const invite_email = (
    to_be_added_emails: string[],
    emails: string[],
    setValues: any,
    setErrors: any,
    setTouched: any
  ) => {
    const invites = to_be_added_emails.filter((email) => {
      return is_valid_email(email) && is_unique_email(email, emails);
    });

    setValues((prev: any) => ({
      ...prev,
      invites: [...emails, ...invites],
      email: "",
    }));

    setErrors((errors: any) => {
      return { ...errors, email: "" };
    });
    setTouched((touched: any) => {
      return { ...touched, email: false };
    });
  };

  const is_unique_email = (email: string, emails: string[]) => {
    if (emails.includes(email)) {
      enqueueSnackbar(`${email} ${t("onboarding.email_already_added")}`, {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const is_valid_email = (email: string) => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return true;
    }

    enqueueSnackbar(`${email} ${t("onboarding.is_not_an_email")}`, {
      variant: "error",
    });
    return false;
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
  });

  return (
    <Grid item container xs={12} justifyContent="center" alignItems="center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setValues, setErrors, setTouched }) => {
          invite_email(
            [values.email],
            values.invites,
            setValues,
            setErrors,
            setTouched
          );
          //   navigate("/onboarding/invite");
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          touched,
          handleChange,
          setValues,
          setErrors,
          setTouched,
        }) => {
          return (
            <Form style={{ width: "100%", maxWidth: "700px" }}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Tooltip
                    title={
                      <Typography variant="body1">
                        {t("onboarding.copy_and_paste_emails")}
                      </Typography>
                    }
                    placement="top-start"
                  >
                    <Box display={"flex"} gap={2} alignItems={"center"} mt={2}>
                      <Typography variant="body1">
                        {t("onboarding.bulk_email")}
                      </Typography>
                      <IconButton color="primary">
                        <HelpOutlineIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Grid>

                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label={t("onboarding.email")}
                    value={values.email}
                    onChange={(e) => {
                      const emailString = e.target.value;
                      const to_be_added_emails = emailString
                        .split(/[\s,]+/)
                        .filter((email) => email.length > 0);
                      if (to_be_added_emails.length > 1) {
                        invite_email(
                          to_be_added_emails,
                          values.invites,
                          setValues,
                          setErrors,
                          setTouched
                        );
                      } else {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.email && errors.email && values.email.length > 0
                    )}
                    helperText={
                      touched.email && values.email.length > 0 && errors.email
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ height: "57px" }}
                    type="submit"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Typography variant="h5">
                        {t("onboarding.invite")}
                      </Typography>
                    </Box>
                  </Button>
                </Grid>

                {values.invites.length > 0 && (
                  <Grid item xs={12}>
                    <OnboardingInviteTable
                      values={values}
                      setValues={setValues}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default OnboardingInviteForm;
