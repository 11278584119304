import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, Tabs, Tab } from "@mui/material";
import { useSnackbar } from "notistack";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "components/Logo/Logo";
import LanguageSwitcher from "components/LanguageSwitcher";
import BrightnessToggleButton from "components/BrightnessToggleButton";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import IndividualRegister from "./IndividualRegister";
import {
  get_countries,
  get_occupations,
  get_sectors,
} from "slices/generalSlice";
import { useDispatch, useSelector } from "store";
import Loader from "components/Loader";
import OrganizationRegister from "./OrganizationRegister";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const RegisterView = () => {
  const { t } = useTranslation();
  const [active_tab, set_active_tab] = useState(0);
  const dispatch = useDispatch();
  const { data: countries, status: countries_status } = useSelector(
    (state) => state.general.countries
  );
  const { data: sectors, status: sectors_status } = useSelector(
    (state) => state.general.sectors
  );
  const { data: occupations, status: occupations_status } = useSelector(
    (state) => state.general.occupations
  );

  useEffect(() => {
    dispatch(get_countries());
    dispatch(get_sectors());
    dispatch(get_occupations());
  }, [dispatch]);

  if (
    countries_status !== "fetched" ||
    sectors_status !== "fetched" ||
    occupations_status !== "fetched"
  ) {
    return <Loader />;
  }
  return (
    <Container component="main" maxWidth="sm" style={{ maxWidth: "600px" }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        // height={"100vh"}
        py={8}
      >
        <Card variant="outlined">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
            p={3}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: { xs: "center", sm: "flex-start" },
                }}
              >
                <Logo />
                <Typography component="h3" variant="body1">
                  {t("auth.login_slogan")}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <LanguageSwitcher />
                  <BrightnessToggleButton />
                </Box>
              </Grid>
            </Grid>

            <Typography component="h1" variant="h5">
              {t("auth.register")}
            </Typography>

            <Box display="flex" justifyContent="center">
              <Tabs
                value={active_tab}
                onChange={(event, new_values) => {
                  set_active_tab(new_values);
                }}
                aria-label="basic tabs example"
                sx={{ borderBottom: 2, borderColor: "divider" }}
              >
                <Tab
                  iconPosition="start"
                  icon={<PersonIcon />}
                  label={t("auth.individual")}
                  {...a11yProps(0)}
                />
                <Tab
                  iconPosition="start"
                  icon={<GroupsIcon />}
                  label={t("auth.organization")}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>

            {active_tab === 0 ? (
              <IndividualRegister />
            ) : (
              <OrganizationRegister />
            )}
          </Box>
          <Grid container p={3}>
            <Grid item xs={12}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                gap={1}
                alignItems={"center"}
              >
                <NavLink to="/login" style={{ textDecoration: "none" }}>
                  <Typography variant="body1" color="primary">
                    {t("auth.login")}
                  </Typography>
                </NavLink>

                <NavLink
                  to="/forgot-password"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1" color="primary">
                    {t("auth.forgot_password")}
                  </Typography>
                </NavLink>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Container>
  );
};

export default RegisterView;
