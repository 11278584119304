import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const OnboardingInviteCredits = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="body1" fontWeight={900} textAlign={"center"}>
        {t("onboarding.credits")}
      </Typography>
      <Typography variant="body2" textAlign={"center"}>
        {`${t("onboarding.full_questionnaire")} : 0 `}
      </Typography>
      <Typography variant="body2" textAlign={"center"}>
        {`${t("onboarding.brief_questionnaire")} : 2 `}
      </Typography>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <NavLink to="/onboarding/purchase" style={{ textDecoration: "none" }}>
          <Typography variant="body1" color="primary" textAlign={"center"}>
            {t("onboarding.top_up")}
          </Typography>
        </NavLink>
      </Box>
    </Box>
  );
};
export default OnboardingInviteCredits;
