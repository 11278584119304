import {
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

interface OnboardingInviteTableProps {
  values: any;
  setValues: any;
}

const OnboardingInviteTable: FC<OnboardingInviteTableProps> = ({
  values,
  setValues,
}) => {
  const invites = values.invites;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell align="center">Invite Status</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      {invites.map((invite: string, index: number) => {
        return (
          <TableRow key={index}>
            <TableCell>{invite}</TableCell>
            <TableCell align="center">
              <CircularProgress color="primary" size={20} />
            </TableCell>
            <TableCell align="right">
              <IconButton
                color="error"
                onClick={() => {
                  const invites = values.invites.filter(
                    (email: string) => email !== invite
                  );
                  setValues({ ...values, invites });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};
export default OnboardingInviteTable;
