import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import "./i18n";
import { SnackbarProvider } from "notistack";
import { SettingsProvider } from "./contexts/SettingsProvider";
import "simplebar/src/simplebar.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <SnackbarProvider>
      <SettingsProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </SettingsProvider>
    </SnackbarProvider>
  </>
);
