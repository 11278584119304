import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import BrightnessToggleButton from "components/BrightnessToggleButton";
import LanguageSwitcher from "components/LanguageSwitcher";
import Logo from "components/Logo/Logo";
import React from "react";
import { useTranslation } from "react-i18next";
import CalculatorViewHeader from "./Header";
import Cal from "..";
import CalculatorFields from "./CalculatorForm/CalculatorFields";
import { Form, Formik } from "formik";
import Loader from "components/Loader";
import CalculatorForm from "./CalculatorForm";

const CalculatorView = () => {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <Container component="main" maxWidth="md">
        <Card variant="outlined">
          <CardContent>
            <CalculatorViewHeader />
            <CalculatorForm />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default CalculatorView;
