// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: "/home/dashboard",
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "en",
  miniDrawer: false,
  container: true,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
};

export default config;
export const drawerWidth = 300;

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const LANGS = {
  EN: "en",
  EL: "el",
};

export const main_api = {
  apiUrl: process.env.REACT_APP_API,
};
