// axiosConfig.js
import axios from "axios";
import { main_api } from "config";

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: main_api.apiUrl,
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.defaults.baseURL = main_api.apiUrl;
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

// Request interceptor to add the auth token to requests
axiosInstance.interceptors.request.use(
  (config) => {
    // Assuming you store your token in localStorage
    const authString = localStorage.getItem("auth");

    if (authString) {
      const auth = JSON.parse(authString ? authString : "");
      config.headers.Authorization = auth.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for any post-processing of responses or handling errors gracefully
axiosInstance.interceptors.response.use(
  (response) => {
    // Successful response handling
    return response;
  },
  (error) => {
    // Check if the error has a response object
    console.error(error);
    if (error.response) {
      // Return a normal response with the error information
      // This avoids throwing an exception and allows you to handle errors based on status code elsewhere in your application
      return Promise.resolve({
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
      });
    } else {
      // In case there's no response (network error, timeout, etc.)
      // You might want to handle these cases differently or return a custom error object
      return Promise.resolve({
        status: 0,
        data: { message: "Network Error or no response received" },
      });
    }
  }
);

export default axiosInstance;
