import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Card, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch } from "../../../store";
import Logo from "components/Logo/Logo";
import LanguageSwitcher from "components/LanguageSwitcher";
import axiosInstance from "utils/axios";
import Loader from "components/Loader";
import BrightnessToggleButton from "components/BrightnessToggleButton";
const debug = process.env.REACT_APP_DEBUG;

const initialValues = debug
  ? {
      email: "giorgos.papakonstantinou1999@gmail.com",
    }
  : {
      email: "",
    };
const ForgotPasswordView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
  });

  const submit_form = async (
    values: any,
    setSubmitting: (param: boolean) => void
  ) => {
    const response = await axiosInstance.post("/user/forgot_password", values);

    if (response.status === 200) {
      enqueueSnackbar(t("auth.reset_password_email"), { variant: "success" });
      navigate("/");
    } else {
      enqueueSnackbar(t("auth.email_not_found"), { variant: "error" });
    }
    setSubmitting(false);
  };

  return (
    <Container component="main">
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"100vh"}
      >
        <Card variant="outlined">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
            p={3}
          >
            <Grid
              container
              spacing={2}
              sx={{
                width: { xs: "320px", md: "550px" },
              }}
            >
              <Grid
                item
                xs={12}
                sm={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: { xs: "center", sm: "flex-start" },
                }}
              >
                <Logo />
                <Typography component="h3" variant="body1">
                  {t("auth.login_slogan")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <LanguageSwitcher />
                  <BrightnessToggleButton />
                </Box>
              </Grid>
            </Grid>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                submit_form(values, setSubmitting);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => {
                return (
                  <Form style={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          {t("auth.email_address")}
                        </Typography>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          type="text"
                          variant="outlined"
                          autoComplete="email"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <NavLink
                            to="/login"
                            style={{ textDecoration: "none" }}
                          >
                            <Typography variant="body1" color="primary">
                              {t("auth.login")}
                            </Typography>
                          </NavLink>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        {isSubmitting && <Loader />}
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          disabled={isSubmitting}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                          >
                            <Typography variant="h5">
                              {t("auth.submit")}
                            </Typography>

                            {isSubmitting && (
                              <CircularProgress color="primary" size={25} />
                            )}
                          </Box>
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default ForgotPasswordView;
