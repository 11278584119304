import { Outlet, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "store";
import Loader from "components/Loader";
import { enqueueSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { get_session_status } from "slices/userSlice";

const AuthGuard: FC = () => {
  const { pathname } = useLocation();
  const session_status = useSelector((state) => state.user.session_status);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (session_status === "idle") {
      dispatch(get_session_status());
    }
  }, [session_status]);

  if (session_status === "succeeded") {
    return <Outlet />;
  }
  if (session_status === "failed") {
    // enqueueSnackbar("You don't have permission to access this page", {
    //   variant: "error",
    // });
    navigate("/login");
  }

  return <Loader />;
};

export default AuthGuard;
