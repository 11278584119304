import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

interface QuestionFormProps {
  question: any;
}
const QuestionForm: FC<QuestionFormProps> = ({ question }) => {
  const [checked_options, set_checked_options] = useState<any>([]);
  const [time, set_time] = useState<number>(question.time);

  let timeout: any;

  useEffect(() => {
    if (time > 0) {
      timeout = setTimeout(() => {
        set_time(time - 1);
      }, 1000);
    } else {
      clearTimeout(timeout);
      alert("Time is up, form submitted!");
      // submit_question(checked_options);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [time]);

  const submit_question = (option: any) => {
    console.log(option);
  };

  const check_option = (option: any) => {
    if (
      checked_options.find(
        (checked_option: any) => checked_option.id === option.id
      )
    ) {
      set_checked_options(
        checked_options.filter(
          (checked_option: any) => checked_option.id !== option.id
        )
      );
    } else {
      if (checked_options.length < question.max_options) {
        set_checked_options([...checked_options, option]);
      } else {
        // remove the first element of array and add the option]
        set_checked_options([...checked_options.slice(1), option]);
      }
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" textAlign={"center"}>
          {question.question}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" textAlign={"center"}>
          {time}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box my={2}>
          {question.options.map((option: any, index: number) => (
            <Grid item xs={12} key={index}>
              <FormControlLabel
                onChange={() => check_option(option)}
                control={
                  <Checkbox
                    checked={
                      checked_options.find(
                        (checked_option: any) => checked_option.id === option.id
                      )
                        ? true
                        : false
                    }
                  />
                }
                label={
                  <Typography variant="body1" textAlign={"center"}>
                    {option.option_value_en}
                  </Typography>
                }
              />
            </Grid>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default QuestionForm;
