import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import BrightnessToggleButton from "components/BrightnessToggleButton";
import LanguageSwitcher from "components/LanguageSwitcher";
import Logo from "components/Logo/Logo";
import { useTranslation } from "react-i18next";

const CalculatorViewHeader = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
      p={3}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", sm: "flex-start" },
          }}
        >
          <Logo />
          <Typography component="h3" variant="body1">
            {t("auth.login_slogan")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-end" },
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-end"}
            justifyContent={"center"}
            gap={2}
          >
            <LanguageSwitcher />
            <BrightnessToggleButton />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalculatorViewHeader;
