import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import OnboardingOrganizationPlanCard from "./OnBoardingOrganizationPlanCard";
import OnboardingMobilePlanCard from "./OnboardingMobilePlanCard";

const OnboardingPricing = () => {
  const { t } = useTranslation();
  return (
    <Box pb={1}>
      <Container maxWidth="md">
        <MainCard
          title={
            <Typography variant="h2" textAlign={"center"}>
              {t("onboarding.subscription_plans")}
            </Typography>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <OnboardingOrganizationPlanCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <OnboardingMobilePlanCard />
            </Grid>
          </Grid>
        </MainCard>
      </Container>
    </Box>
  );
};

export default OnboardingPricing;
