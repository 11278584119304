import { createSlice } from "@reduxjs/toolkit";
import { AuthUser } from "types/auth_user";
import { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "utils/axios";
import { RequestStatus } from "types/general";

interface UserState {
  auth: AuthUser | null;
  session_status: RequestStatus;
}

const initialState: UserState = {
  auth: null,
  session_status: "idle",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set_auth_user(state: UserState, action: PayloadAction<AuthUser | null>) {
      state.auth = action.payload;
    },
    set_session_status(state: UserState, action: PayloadAction<RequestStatus>) {
      state.session_status = action.payload;
    },
  },
});

export const reducer = userSlice.reducer;

export const set_auth_user = (user: AuthUser) => async (dispatch: any) => {
  dispatch(userSlice.actions.set_auth_user(user));
  localStorage.setItem("auth", JSON.stringify(user));
};

export const get_session_status = () => async (dispatch: any) => {
  dispatch(userSlice.actions.set_session_status("loading"));

  const response = await axiosInstance.get("/user/questions");

  if (response.status === 200) {
    const auth_string = localStorage.getItem("auth");
    const auth = auth_string ? JSON.parse(auth_string) : null;
    dispatch(userSlice.actions.set_auth_user(auth));
    dispatch(userSlice.actions.set_session_status("succeeded"));
  } else {
    dispatch(userSlice.actions.set_session_status("failed"));
  }
};

export const login = (user: any) => async (dispatch: any) => {
  dispatch(userSlice.actions.set_auth_user(user));
  localStorage.setItem("auth", JSON.stringify(user));
  dispatch(userSlice.actions.set_session_status("succeeded"));
};

export const logout = () => async (dispatch: any) => {
  localStorage.removeItem("auth");
  dispatch(userSlice.actions.set_auth_user(null));
  dispatch(userSlice.actions.set_session_status("idle"));
};
