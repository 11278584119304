import React, { FC, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Box, Button, Container, CssBaseline } from "@mui/material";
import LoginView from "./views/auth/LoginView";
import RegisterView from "./views/auth/RegisterView";
import ThemeCustomization from "./themes";
import { useSettings } from "hooks/useSettings";
import MainLayout from "layout/MainLayout";
import AuthGuard from "routes/AuthGuard";
import ForgotPasswordView from "views/auth/ForgotPasswordView";
import Dashboard from "views/dashboard";
import Onboarding from "views/onboarding";
import OnBoardingHeader from "views/onboarding/OnBoardingHeader";
import OnboardingWelcome from "views/onboarding/OnboardingWelcome";
import OnboardingInvite from "views/onboarding/OnboardingInvite";
import OnBoardingTeam from "views/onboarding/OnBoardingTeam";
import OnboardingTeamLeader from "views/onboarding/OnboardingTeamLeader";
import OnboardingPricing from "views/onboarding/OnBoardingPricing";
import Questionnaire from "views/questionnaire";
import Section from "views/questionnaire/section";
import Questions from "views/questionnaire/section/questions";
import CalculatorView from "views/calculator/CalculatorView";

const App: FC = () => {
  const { settings } = useSettings();

  return (
    <ThemeCustomization mode={settings.theme}>
      <Box>
        <Router>
          <Routes>
            <Route path="/value-calculator" element={<CalculatorView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/register" element={<RegisterView />} />
            <Route path="/forgot-password" element={<ForgotPasswordView />} />

            <Route path="/onboarding" element={<AuthGuard />}>
              <Route path="/onboarding" element={<OnBoardingHeader />}>
                <Route
                  path="/onboarding/questionnaire"
                  element={<Questionnaire />}
                />
                <Route
                  path="/onboarding/questionnaire/section"
                  element={<Section />}
                />
                <Route
                  path="/onboarding/questionnaire/section/questions"
                  element={<Questions />}
                />

                <Route
                  path="/onboarding/welcome"
                  element={<OnboardingWelcome />}
                />
                <Route path="/onboarding/team" element={<OnBoardingTeam />} />
                <Route
                  path="/onboarding/invite"
                  element={<OnboardingInvite />}
                />
                <Route
                  path="/onboarding/team_leader"
                  element={<OnboardingTeamLeader />}
                />
                <Route
                  path="/onboarding/pricing"
                  element={<OnboardingPricing />}
                />
              </Route>
            </Route>

            <Route path="/" element={<AuthGuard />}>
              <Route path="/" element={<MainLayout />}>
                <Route path="/home/dashboard" element={<Dashboard />} />
                <Route path="/home/groups" element={<div>Groups</div>} />
                <Route path="/" element={<Navigate to="/home/dashboard" />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </Box>
    </ThemeCustomization>
  );
};

export default App;
