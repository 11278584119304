import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar } from "@mui/material";
import Header from "./Header";
import navigation from "menu-items";
import Breadcrumbs from "components/@extended/Breadcrumbs";

const OnBoardingHeader = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
      >
        <Toolbar />
        <Breadcrumbs navigation={navigation} title />
        <Outlet />
      </Box>
    </Box>
  );
};

export default OnBoardingHeader;
