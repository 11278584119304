import { Grid } from "@mui/material";
import CalculatorFields from "./CalculatorFields";
import { Form, Formik } from "formik";
import CalculatorResults from "./CalculatorResults";

const CalculatorForm = () => {
  return (
    <Formik
      initialValues={{
        number_of_staff: 100,
        team_size: 10,
      }}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // submit_form(values, setSubmitting);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setValues,
      }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CalculatorFields
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CalculatorResults
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CalculatorForm;
