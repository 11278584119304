import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useDispatch } from "store";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import OnboardingTeamLeaderCredits from "./OnboardingInviteCredits";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { set } from "lodash";
import OnboardingTeamLeaderForm from "./OnboardingTeamLeaderForm";

const initialValues = {
  email: "",
  invites: [] as string[],
  loading: false,
};

const OnboardingTeamLeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const invite_email = (
    values: any,
    setValues: any,
    setErrors: any,
    setTouched: any
  ) => {
    const invites = [...values.invites, values.email];
    setValues({ ...values, invites, email: "" });
    setErrors((errors: any) => {
      return { ...errors, email: "" };
    });
    setTouched((touched: any) => {
      return { ...touched, email: false };
    });
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
  });

  return (
    <Box pb={1}>
      <Container maxWidth="md">
        <MainCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" textAlign={"center"}>
                {t("onboarding.step_3")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" textAlign={"center"}>
                {t("onboarding.team_leader_questionnaire")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <OnboardingTeamLeaderCredits />
            </Grid>

            <Grid item xs={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <Typography
                  variant="body1"
                  fontWeight={900}
                  textAlign={"center"}
                >
                  {`${t(
                    "onboarding.option_1_fill_out_team_leader_questionnaire"
                  )}`}
                </Typography>

                <NavLink
                  to="/onboarding/purchase"
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained">
                    <Typography variant="body1" textAlign={"center"}>
                      {t("onboarding.fill_out")}
                    </Typography>
                  </Button>
                </NavLink>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <Typography variant="h5" fontWeight={900} textAlign={"center"}>
                  {`${t("onboarding.or")}`}
                </Typography>

                <Typography
                  variant="body1"
                  fontWeight={900}
                  textAlign={"center"}
                >
                  {`${t("onboarding.option_2_invite_team_leader")}`}
                </Typography>
              </Box>
            </Grid>

            <OnboardingTeamLeaderForm />
            <Grid item xs={12} mt={2}>
              <Box display={"flex"} justifyContent={"end"}>
                <NavLink
                  to="/onboarding/team_leader"
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained">
                    <Typography variant="body1" textAlign={"center"}>
                      {t("onboarding.invite_team_leader")}
                    </Typography>
                  </Button>
                </NavLink>
              </Box>
            </Grid>
          </Grid>
        </MainCard>
      </Container>
    </Box>
  );
};

export default OnboardingTeamLeader;
