import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./locale/en.json";
import EL from "./locale/el.json";

const resources = {
  en: {
    translation: EN,
  },
  el: {
    translation: EL,
  },
};

let userLang: any = "en";
let settings: any = localStorage.getItem("settings");

if (settings) {
  settings = JSON.parse(settings);
  userLang = settings ? settings["lang"] : "en";
}

i18n.use(initReactI18next).init({
  resources,
  lng: userLang,
  fallbackLng: "en",
});

export default i18n;
