import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import HomeIcon from "@mui/icons-material/Home";
import StorefrontIcon from "@mui/icons-material/Storefront";
import GridViewIcon from "@mui/icons-material/GridView";

// icons
const icons = {
  HomeIcon,
  StorefrontIcon,
  GridViewIcon,
  Inventory2OutlinedIcon,
};

const group = {
  id: "group",
  title: "menu.group",
  type: "group",

  children: [
    {
      id: "groups",
      title: "menu.groups",
      type: "item",
      url: "/home/groups",
      icon: icons.GridViewIcon,
    },
  ],
};

export default group;
