import { Container } from "@mui/material";
import MainCard from "components/MainCard";
import { useSelector } from "store";

const UserGroup = () => {
  const { data: demo_group, status: demo_group_status } = useSelector(
    (state) => state.group.demo_group
  );

  return (
    <MainCard title="Demo Group Joined">
      <Container>
        <pre>{JSON.stringify(demo_group, null, 2)}</pre>
      </Container>
    </MainCard>
  );
};
export default UserGroup;
