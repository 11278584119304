import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "utils/axios";
import {
  Country,
  FetchStatus,
  Occupation,
  RequestStatus,
  Sector,
} from "types/general";

interface GeneralState {
  countries: {
    data: Country[];
    status: FetchStatus;
  };
  sectors: {
    data: Sector[];
    status: FetchStatus;
  };
  occupations: {
    data: Occupation[];
    status: FetchStatus;
  };
}

const initialState: GeneralState = {
  countries: {
    data: [],
    status: "not_fetched",
  },
  sectors: {
    data: [],
    status: "not_fetched",
  },
  occupations: {
    data: [],
    status: "not_fetched",
  },
};

const generalSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set_countries_fetch_status(
      state: GeneralState,
      action: PayloadAction<FetchStatus>
    ) {
      state.countries.status = action.payload;
    },
    set_countries(state: GeneralState, action: PayloadAction<Country[]>) {
      state.countries.data = action.payload;
      state.countries.status = "fetched";
    },
    set_sectors_fetch_status(
      state: GeneralState,
      action: PayloadAction<FetchStatus>
    ) {
      state.sectors.status = action.payload;
    },
    set_sectors(state: GeneralState, action: PayloadAction<Sector[]>) {
      state.sectors.data = action.payload;
      state.sectors.status = "fetched";
    },
    set_occupations_fetch_status(
      state: GeneralState,
      action: PayloadAction<FetchStatus>
    ) {
      state.occupations.status = action.payload;
    },
    set_occupations(state: GeneralState, action: PayloadAction<Occupation[]>) {
      state.occupations.data = action.payload;
      state.occupations.status = "fetched";
    },
  },
});

export const reducer = generalSlice.reducer;

export const get_countries = () => async (dispatch: any) => {
  try {
    dispatch(generalSlice.actions.set_countries_fetch_status("fetching"));
    const response = await axiosInstance.get("/countries");
    if (response.status === 200) {
      dispatch(generalSlice.actions.set_countries(response.data.data));
    } else {
      throw new Error("Failed to fetch countries");
    }
  } catch (error) {
    dispatch(generalSlice.actions.set_countries_fetch_status("failed"));
  }
};

export const get_sectors = () => async (dispatch: any) => {
  try {
    dispatch(generalSlice.actions.set_sectors_fetch_status("fetching"));
    const response = await axiosInstance.get("/sector");
    if (response.status === 200) {
      dispatch(generalSlice.actions.set_sectors(response.data.data));
    } else {
      throw new Error("Failed to fetch sectors");
    }
  } catch (error) {
    dispatch(generalSlice.actions.set_sectors_fetch_status("failed"));
  }
};

export const get_occupations = () => async (dispatch: any) => {
  try {
    dispatch(generalSlice.actions.set_occupations_fetch_status("fetching"));
    const response = await axiosInstance.get("/occupation");
    if (response.status === 200) {
      dispatch(generalSlice.actions.set_occupations(response.data.data));
    } else {
      throw new Error("Failed to fetch occupations");
    }
  } catch (error) {
    dispatch(generalSlice.actions.set_occupations_fetch_status("failed"));
  }
};

// export const set_auth_user = (user: AuthUser) => async (dispatch: any) => {
//   dispatch(generalSlice.actions.set_auth_user(user));
//   localStorage.setItem("auth", JSON.stringify(user));
// };
